import { useState, useEffect, useRef } from 'react';
/**
 * useDeferredFetch hook conditionally executes *request* when *invocationAllowed* is true
 * @param {function} request - function which invokes fetch request
 * @param {boolean} invocationAllowed - indicates whether to invoke request function
 * @returns initiateRequest function which should be invoked with the same args as specified in *request* function
 */
export const useDeferredFetch = (request, invocationAllowed) => {
  const [isFinished, setIsFinished] = useState(true);
  const [requestParams, setRequestParams] = useState(undefined);
  const resolveRef = useRef(null);
  const rejectRef = useRef(null);
  const promiseRequestedRef = useRef(null);

  const initiateRequest = (...args) => {
    setIsFinished(false);
    setRequestParams(args);
  };

  const initiateRequestPromise = (...args) => {
    promiseRequestedRef.current = true;
    setIsFinished(false);
    setRequestParams(args);

    return new Promise((resolve, reject) => {
      resolveRef.current = resolve;
      rejectRef.current = reject;
    });
  };

  useEffect(() => {
    (async () => {
      if (isFinished || !invocationAllowed) return;
      console.log(promiseRequestedRef);

      if (!!promiseRequestedRef.current) {
        try {
          const resp = await request(...requestParams);
          resolveRef.current(resp);
        } catch (err) {
          console.error(err);
          rejectRef.current(null);
        }

        setIsFinished(true);
      } else {
        await request(...requestParams);
        setIsFinished(true);
      }
    })();
  }, [invocationAllowed, isFinished, request, requestParams, promiseRequestedRef.current]);

  return { initiateRequest, initiateRequestPromise };
};
