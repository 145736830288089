import React, { useState } from 'react';
import classNames from 'classnames';
import { FormattedMessage, FormattedHTMLMessage, useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import Footer from '../Footer/Footer';
import NewsletterSection from '../NewsletterSection/NewsletterSection';
import RecentlyViewedProducts from '../RecentlyViewedProducts/RecentlyViewedProducts';
import StickyHeader from '../StickyHeader/StickyHeader';

import styles from './AllShopsPage.module.scss';
import { Link, useLoaderData } from 'react-router-dom';
import { clipTextEllipsis } from '../../utils/textEllipsis';

const AllShopsPage = ({ config }) => {
  const intlFull = useIntl();
  const { allActiveShops } = useLoaderData();

  const [selectedLetter, setSelectedLetter] = useState(false);
  const [inputSearchValue, setInputSearchValue] = useState('');

  let shopsMap;

  if (!!allActiveShops) {
    const arrayOfShops = allActiveShops.filter((item) =>
      item.name?.toLowerCase().includes(inputSearchValue.toLowerCase())
    );
    shopsMap = {
      '0-9': arrayOfShops.filter((shop) => !isNaN(parseInt(shop.name[0], 10))),
    };

    for (let charCode = 65; charCode < 91; charCode++) {
      const letter = String.fromCharCode(charCode);
      shopsMap[letter] = arrayOfShops
        .filter((shop) => shop.name !== '')
        .filter((shop) => shop.name[0].toUpperCase() === letter)
        .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));
    }
  }

  const scrollToShop = (letter) => {
    setSelectedLetter(letter);
    const elm = document.getElementById(letter);
    window.scrollTo({ behavior: 'smooth', left: 0, top: elm.offsetTop - 75 });
  };

  const searchShop = (e) => {
    if (e.target.value) {
      setSelectedLetter(false);
    }
    setInputSearchValue(e.target.value);
  };

  const pageTitle = intlFull.formatMessage(
    {
      id: 'allShops.title',
    },
    {
      webShopName: config.websiteName,
    }
  );

  return (
    <>
      <Helmet>
        <title>{clipTextEllipsis(pageTitle, 60)}</title>
        <meta
          name="description"
          content={intlFull.formatMessage({ id: 'allShops.metadesc' }, { webShopName: config.websiteName })}
        />
        <meta property="og:url" content={`${config.baseUrl}/${config.cmsSlugs.webshops}/`} />
        <link rel="canonical" href={`${config.baseUrl}/${config.cmsSlugs.webshops}/`} />
      </Helmet>
      <StickyHeader />
      {allActiveShops && (
        <div className={classNames(styles.allShops, 'container')} data-testid="allShopsPage" id="readyForCrawler">
          <div className="row">
            <section className="col-12-xs">
              <h1 className={styles.title}>
                <FormattedMessage id="common.brands.all" defaultMessage="All" />{' '}
                <span>
                  <FormattedMessage id="common.shops" defaultMessage="shops" />
                </span>
              </h1>
              <div className={styles.wrapperSearchInputShop}>
                {!inputSearchValue && (
                  <label htmlFor="searchInput">
                    <FormattedHTMLMessage
                      defaultMessage="Search here for the webshop. For example <b>HEMA</b>, <b>BCC</b>, <b>Blokker</b>, <b>Coolblue</b>, <b>Wehkamp</b>, etc."
                      id="common.shopsPlaceholder"
                    ></FormattedHTMLMessage>
                  </label>
                )}
                <input
                  autoComplete="off"
                  className={styles.searchInputShop}
                  value={inputSearchValue}
                  onInput={searchShop}
                  id="searchInput"
                />
              </div>
              <nav className={styles.navigation}>
                {Object.keys(shopsMap).map((letter, index) => {
                  return (
                    <span
                      className={classNames(styles.letter, {
                        [styles.disabled]: !shopsMap[letter].length,
                        [styles.selected]: letter === selectedLetter,
                      })}
                      data-testid={`allShopsScroll${letter.toLowerCase()}`}
                      key={letter + index}
                      onClick={() => scrollToShop(letter.toUpperCase())}
                    >
                      {letter}
                    </span>
                  );
                })}
              </nav>
              {Object.keys(shopsMap)
                .filter((letter) => shopsMap[letter].length)
                .map((letter, index) => (
                  <section className={styles.section} id={letter} key={letter + index}>
                    <h2 data-testid="AllShopsSectionTitle" className={styles.sectionTitle}>
                      <b>{letter.toUpperCase()}</b>
                    </h2>
                    <div className={classNames(styles.shopList, 'row')}>
                      <div className={styles.wrapWebsite}>
                        {shopsMap[letter].map((shop, index) => (
                          <div className={classNames(styles.website)} key={shop.name + index}>
                            <Link
                              className={styles.shopName}
                              data-testid="AllShopsShopName"
                              rel="noopener noreferrer"
                              to={shop.slug}
                            >
                              {shop.name}
                            </Link>
                          </div>
                        ))}
                      </div>
                    </div>
                  </section>
                ))}
            </section>
          </div>
        </div>
      )}
      <RecentlyViewedProducts />
      <NewsletterSection />
      <Footer />
    </>
  );
};

AllShopsPage.displayName = 'AllShopsPage';

const mapStateToProps = ({ config, profile }) => ({ config, profile });

export default connect(mapStateToProps)(AllShopsPage);
