/* eslint-disable react/prop-types */
import React from 'react';
import { FormattedMessage } from 'react-intl';

import styles from './WishlistButton.module.scss';

import { ReactComponent as WishlistButtonSelectedIcon } from '../../images/icons/wishlist.svg';
import { ReactComponent as WishlistButtonIcon } from '../../images/icons/wishlist_transparent.svg';
import useWishlist from '../../utils/useWishlist';
import classNames from 'classnames';

export const WishlistButton = ({ productId, className }) => {
  const { toggleWishlistItem, isWishlistItem } = useWishlist();

  const isLikedProduct = isWishlistItem(productId);

  const message = isLikedProduct ? 'Remove from wishlist' : 'Add to wishlist';
  const idIntl = isLikedProduct ? 'profile.wishlistRemove' : 'profile.wishlistAdd';
  const icon = isLikedProduct ? (
    <WishlistButtonSelectedIcon className={styles.likedProductIcon} />
  ) : (
    <WishlistButtonIcon />
  );

  const handleWishlistBtnPress = () => {
    toggleWishlistItem(productId);
  };

  return (
    <>
      <FormattedMessage id={idIntl} defaultMessage={message}>
        {(label) => (
          <button
            aria-label={label}
            data-pid={productId}
            className={classNames(className || styles.wishlistBtn)}
            onClick={handleWishlistBtnPress}
          >
            {icon}
          </button>
        )}
      </FormattedMessage>
    </>
  );
};
