import classNames from 'classnames';
import React from 'react';
import loadable from '@loadable/component';
import styles from './CartOverviewPage.module.scss';
import Price from '../Price/Price';
import { Link } from 'react-router-dom';
import { ReactComponent as Delete } from '../../images/icons/delete.svg';
import { FormattedMessage } from 'react-intl';
import { WishlistButton } from '../WishlistButton/WishlistButton';
import { useCart } from '../CartBootstrap/useCart';

const ProductCount = loadable(() => import('../ProductCount/ProductCount'));

const Shop = ({ cartItem, disabledQuantity = 0 }) => {
  const { removeProductFromCart } = useCart();
  const { shop, products } = cartItem;
  const removeProduct = (id, shopId, merchantPID) => () => {
    removeProductFromCart(id, shopId, merchantPID);
  };

  return (
    <div className={styles.shop}>
      <span className={styles.shopName}>{shop.name}</span>
      {!!products?.length &&
        products.map((product) => (
          <div
            key={`${product.id}InCartPopup`}
            className={classNames(styles.product, { [styles.productUnavailable]: !product.available })}
          >
            <div className={styles.productPicture}>
              <img src={product.picture} />
            </div>
            {!!product.pdp_link && (
              <div className={styles.productName}>
                <Link className={classNames(styles.productLink)} to={product.pdp_link}>
                  {product.name}
                </Link>
                {!!product.size?.[0] && (
                  <span className={styles.productSize}>
                    <FormattedMessage
                      id="orderDetails.size"
                      values={{ size: product.size[0] }}
                      defaultValue="Size: {size}"
                    />
                  </span>
                )}
              </div>
            )}
            {!product.pdp_link && <p className={styles.productName}>{product.name}</p>}
            <div className={styles.productQuantity}>
              {!!product.available && (
                <ProductCount
                  productId={product.id}
                  merchantPID={product?.merchant_product_id}
                  shopId={shop?.id}
                  quantity={product.quantity}
                  disabledQuantity={disabledQuantity}
                />
              )}
              {!product.available && (
                <FormattedMessage id="cart.itemUnavailable" defaultMessage="Not available anymore" />
              )}
            </div>
            <div className={styles.productPrice}>
              {!!product.available && (
                <Price
                  showDiscount={false}
                  currency={process.env.REACT_APP_WEBSITE_CURRENCY}
                  price={product.total}
                  // previousPrice={shop.previousPrice || shop.price_original}
                />
              )}
            </div>
            <div className={styles.productActions}>
              {!product.available && <WishlistButton productId={product.id} />}
              <Delete
                className={styles.deleteIcon}
                onClick={removeProduct(product.id, shop?.id, product?.merchant_product_id)}
                width="1.5rem"
                height="1.5rem"
              />
            </div>
          </div>
        ))}
    </div>
  );
};

export default Shop;
