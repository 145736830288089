import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useCart } from '../CartBootstrap/useCart';
import StickyHeader from '../StickyHeader/StickyHeader';
import styles from './CartOverviewPage.module.scss';
import Shop from './Shop';
import { FormattedMessage } from 'react-intl';
import Footer from '../Footer/Footer';
import NewsletterSection from '../NewsletterSection/NewsletterSection';
import RecentlyViewedProducts from '../RecentlyViewedProducts/RecentlyViewedProducts';
import CheckoutSidebar from '../CheckoutSidebar/CheckoutSidebar';
import { ReactComponent as PugShadow } from '../../images/icons/pug_shadow.svg';
import { ReactComponent as ArrowRight } from '../../images/icons/arrowRight.svg';
import Button, { SECONDARY } from '../Button/Button';
import { useAuth0Wrapper } from '../../utils/auth0';
import { useSelector } from 'react-redux';

const CartOverviewPage = () => {
  const { openCarts, pendingPayments, cartErrors, cartSessionHash, updateCart } = useCart();
  const { tokenClaims } = useAuth0Wrapper();
  const config = useSelector(({ config }) => config);

  const authToken = tokenClaims?.__raw;

  const totals = openCarts?.reduce(
    (acc, shop) => ({ price: acc.price + shop.products_cost, quantity: acc.quantity + shop.quantity }),
    { price: 0, quantity: 0 }
  );
  const totalQuantity = totals?.quantity;
  const totalPrice = totals?.price;

  const lastPendingPayments = pendingPayments?.[pendingPayments.length - 1];

  useEffect(() => {
    if (cartSessionHash || authToken) {
      updateCart();
    }
  }, [cartSessionHash, authToken]);

  return (
    <>
      <StickyHeader />
      <div className={classNames('container', styles.root, { [styles.rootEmpty]: !openCarts?.length })}>
        <h1 className={styles.pageHeader}>
          <FormattedMessage id="cart.overviewHeading" defaultMessage="Shopping cart" />
        </h1>
        <section className={styles.shopsList}>
          {!!pendingPayments.length && (
            <div className={styles.pendingContainer}>
              <div className={styles.paymentPending}>
                <div className={styles.paymentPendingLeft}>
                  <div>
                    <h1>
                      <FormattedMessage
                        id="cart.pendingPayment.title"
                        defaultMessage="The order is waiting for payment"
                      />
                    </h1>
                    <span>
                      <FormattedMessage
                        id="cart.pendingPayment.note"
                        defaultMessage="After 30 minutes, it will have to be removed"
                      />
                    </span>
                  </div>
                  <a href={lastPendingPayments?.payment_link}>
                    <Button variation={SECONDARY}>
                      <FormattedMessage id="cart.pendingPayment.payOrder" defaultMessage="Pay for the order" />
                      <ArrowRight />
                    </Button>
                  </a>
                </div>
                <div className={styles.paymentPendingRight} />
              </div>
              <div className={styles.paymentSupport}>
                <div>
                  <p>
                    <FormattedMessage id="cart.pendingPayment.problem" defaultMessage="Payment problem?" />
                  </p>
                  <span>
                    <FormattedMessage
                      id="cart.pendingPayment.help"
                      defaultMessage="Tell us why you didn't pay for the order, it will help make Webshop better"
                      values={{ webShopName: config.websiteName }}
                    />
                  </span>
                </div>
                <div>
                  <Button variation={SECONDARY} inverted>
                    <FormattedMessage id="cart.pendingPayment.button" defaultMessage="Write to support" />
                  </Button>
                </div>
              </div>
            </div>
          )}
          {!!openCarts?.length &&
            openCarts.map((cartItem) => <Shop key={cartItem?.shop?.name} cartItem={cartItem} disabledQuantity={1} />)}
          {!openCarts?.length && !pendingPayments.length && (
            <div className={styles.empty}>
              <div className={styles.pugPlace}>
                <div className={styles.pug} />
                <PugShadow className={styles.pugShadow} />
              </div>
              <span className={styles.emptyMessage}>
                <FormattedMessage id="cart.empty" defaultMessage="There are no products in your cart yet" />
              </span>
            </div>
          )}
        </section>
        <CheckoutSidebar
          totalQuantity={totalQuantity}
          totalProductsPrice={totalPrice}
          className={styles.sidebar}
          errors={cartErrors}
        />
      </div>
      <RecentlyViewedProducts />
      <NewsletterSection />
      <Footer />
    </>
  );
};

export default CartOverviewPage;
