import { useEffect, useCallback, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { generateRandomHash } from 'Utils/generateRandomHash';
import { setCartErrors, setCartSession, setPendingPayment } from '../../state/cart/cart';
import { getValidResponseBody, cartApi } from '../../api';
import { setCartContents } from '../../state/cart/cart';
import { useDeferredFetch } from '../../utils/useDeferredFetch';
import useNotifications from '../NotificationPopup/useNotifications';
import { useAuth0Wrapper } from '../../utils/auth0';
import { ERROR_NOTIFICATION, SUCCESS_NOTIFICATION } from '../../state/notifications/notifications';
import { useIntl } from 'react-intl';
import { isMPGTMEnabled } from '../../utils/isGTMEnabled';
import dataLayerPush from '../../utils/dataLayerPush';

export const useCart = () => {
  const [cookies, setCookie] = useCookies(['cartSessionHash']);
  const intl = useIntl();
  const { tokenClaims, logoutExpiredJWT } = useAuth0Wrapper();
  const authToken = tokenClaims?.__raw;

  const cart = useSelector(({ cart }) => cart);
  const { cartSessionHash, openCarts, pendingPayments, cartErrors } = cart;
  const dispatch = useDispatch();
  const { sendNotification } = useNotifications();
  const [ordersList, setOrdersList] = useState([]);
  const [returnsList, setReturnsList] = useState([]);
  const [isLoadingCard, setIsLoadingCard] = useState(true);
  const [isGetReturnList, setIsGetReturnList] = useState(true);
  const [isGetOrderList, setIsGetOrderList] = useState(true);

  const cartSessionFromCookies = cookies['cartSessionHash'];

  const sendValidServerNotification = useCallback(
    (resp, fallbackText) => {
      const notificationStatus = !resp.ok ? ERROR_NOTIFICATION : SUCCESS_NOTIFICATION;
      const notificationText = resp?.isJSON ? resp?.body : fallbackText;
      sendNotification(notificationText, notificationStatus);
    },
    [sendNotification]
  );

  const updateCart = useCallback(
    async (verbose = true) => {
      const cartsResp = await cartApi.openCarts(cartSessionHash, authToken);
      if (verbose && !cartsResp.ok) {
        sendValidServerNotification(
          cartsResp,
          'Unable to retrieve a cart contents due to a server error. Try again later'
        );
        if (isLoadingCard) {
          setIsLoadingCard(false);
        }
        return;
      }
      //todo: remove next line, instead of reloading the page, just reset redux profile
      if (window.forcedLogout) cartsResp.status = 401;
      logoutExpiredJWT(cartsResp);
      const cartsData = getValidResponseBody(cartsResp);
      dispatch(setCartContents(cartsData?.carts));
      dispatch(setPendingPayment(cartsData?.pending_payment || []));
      dispatch(setCartErrors(cartsData?.errors || []));
      if (isLoadingCard) {
        setIsLoadingCard(false);
      }
    },
    [cartSessionHash, authToken, logoutExpiredJWT, dispatch]
  );

  //todo: KEEP IT SIMPLE, PLEASE
  const { initiateRequest: addToCartDeferredRequest } = useDeferredFetch(
    (productId, shopId, merchantPid, onFullfilled, onError) => {
      cartApi
        .addProductToCart(productId, shopId, merchantPid, cartSessionHash, authToken)
        .then((resp) => {
          //todo: update it
          if (!resp.ok) {
            logoutExpiredJWT(resp);
            sendValidServerNotification(resp, intl.formatMessage({ id: 'common.error.api' }));
          }
          if (!resp.ok && onError) onError();

          if (resp.ok && onFullfilled) onFullfilled();
          if (resp.ok) updateCart();
        })
        .catch(() => {
          if (onError) onError();
          sendNotification('Unable to add to card. Something went wrong with the product', ERROR_NOTIFICATION);
        });
    },
    cartSessionHash
  );

  const addToCart = (id, shopId, merchantPid, onFullfilled, onError) => {
    if (!cartSessionHash) generateSessionHash();
    if (isMPGTMEnabled) dataLayerPush({ event: 'add_to_cart', product_id: id });
    return addToCartDeferredRequest(id, shopId, merchantPid, onFullfilled, onError);
  };

  const { initiateRequest: decrementProductQuantity } = useDeferredFetch((productId, shopId, merchantPid) => {
    cartApi.decrementProductQuantity(productId, shopId, merchantPid, cartSessionHash, authToken).then((resp) => {
      if (!resp?.ok) sendValidServerNotification(resp, intl.formatMessage({ id: 'common.error.api' }));
      updateCart();
    });
  }, cartSessionHash);

  const { initiateRequest: removeProductFromCart } = useDeferredFetch((productId, shopId, merchantPID) => {
    cartApi.removeProductFromCart(productId, shopId, merchantPID, cartSessionHash, authToken).then((resp) => {
      if (!resp?.ok) sendValidServerNotification(resp, intl.formatMessage({ id: 'common.error.api' }));
      updateCart();
    });
  }, cartSessionHash);

  const { initiateRequest: removeAllProductsFromCart } = useDeferredFetch((openCarts) => {
    cartApi.removeAllProductsFromCart(openCarts, cartSessionHash, authToken).then(() => {
      updateCart();
    });
  }, cartSessionHash);

  const { initiateRequest: getOrdersList } = useDeferredFetch(async () => {
    setIsGetOrderList(true);
    const resp = await cartApi.getOrdersList(authToken);
    if (resp?.ok && resp?.body?.data?.orders?.length) setOrdersList(resp?.body?.data?.orders);
    else logoutExpiredJWT(resp);
    setIsGetOrderList(false);
  }, authToken);

  const { initiateRequest: getReturnsList } = useDeferredFetch(async () => {
    setIsGetReturnList(true);
    const resp = await cartApi.getReturnsList(authToken);
    if (resp?.ok && resp?.body?.data?.returns?.length) setReturnsList(resp?.body?.data?.returns);
    else logoutExpiredJWT(resp);
    setIsGetReturnList(false);
  }, authToken);

  const { initiateRequestPromise: getAddressesList } = useDeferredFetch(() => {
    return cartApi.getAddressesList(authToken); //.then((resp) => {
    //if (resp?.ok && resp?.body?.data?.returns?.length) setReturnsList(resp?.body?.data?.returns);
    //else logoutExpiredJWT(resp);
    //});
  }, authToken);

  //Call this function to save session hash in cookies
  const generateSessionHash = useCallback(() => {
    const hash = generateRandomHash(32);
    const expDate = new Date();
    expDate.setFullYear(expDate.getFullYear() + 1);

    setCookie('cartSessionHash', hash, {
      expires: expDate,
      path: '/',
    });
  }, [setCookie]);

  const declareReturn = async (orderId, shopId, selectedProducts, returnReason) => {
    const resp = await cartApi.declareReturn(
      orderId,
      shopId,
      selectedProducts,
      returnReason,
      authToken,
      cartSessionHash
    );
    const notificationStatus = resp.ok ? SUCCESS_NOTIFICATION : ERROR_NOTIFICATION;
    const fallbackText = resp.ok
      ? intl.formatMessage({ id: 'ordersAndReturns.returnDeclared' })
      : intl.formatMessage({ id: 'common.error.api' });
    const stringResponse = typeof resp.body === 'string';

    if (stringResponse) sendValidServerNotification(resp, fallbackText);
    if (Array.isArray(resp.body)) {
      resp.body.forEach((message) => sendNotification(message, notificationStatus));
    }
  };

  //New session hash from cookies gets stored in redux
  useEffect(() => {
    if (!cartSessionHash && cartSessionFromCookies) {
      dispatch(setCartSession(cartSessionFromCookies));
    } else if (!cartSessionHash && !cartSessionFromCookies) {
      generateSessionHash();
    }
  }, [cartSessionHash, cartSessionFromCookies, dispatch, generateSessionHash]);

  return {
    ordersList,
    cartSessionHash,
    openCarts,
    updateCart,
    getOrdersList,
    addToCart,
    removeProductFromCart,
    decrementProductQuantity,
    generateSessionHash,
    declareReturn,
    getReturnsList,
    returnsList,
    getAddressesList,
    pendingPayments,
    isLoadingCard,
    cartErrors,
    isGetOrderList,
    isGetReturnList,
    removeAllProductsFromCart,
  };
};
